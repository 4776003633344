export let data =[
  '{"name": "Padma", "place": "Chennai", "waNumber": "919444078614", "email": "padmathiagarajan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fe16a6ce8c705ae59745549a0cadf4e2d7d2872eb339264a1f3fc0e491d6153f/1610697823797.jpg", "scribbleSpace": "Chartered accountant  by profession.. Fitness, art, music is my passion..", "contribution": "Importance of being fit.", "intro": "Chartered accountant by profession.. Fitness, art and music is my passion.."}',
  '{"name": "Parth Agarwal", "place": "Najibabad, Uttar Pradesh", "waNumber": "917078609133", "email": "parthagarwal1984@gmail.com", "photoLine": "https://api.typeform.com/responses/files/744a0a0e3c2a4eadecd87cf78aeeaece870b01ee3a40439837133987648c8e25/20210619_122820.jpg", "scribbleSpace": "I want to change how education works in India/", "contribution": "I can share things about freelancing, productivity and visualisation", "intro": "educator, entreprenuer"}',
  '{"name": "Pankaj Mishra", "place": "Wakad, Pune", "waNumber": "919167191835", "email": "mishra.pa@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0152b92c0322f9a84cfddff18f181bd07aef8785168138ac75451e308ae85826/_TP_9259_Edit.jpg", "scribbleSpace": "Focus on doing daily exercise, plan for the day", "contribution": "Indian cities - my experiences", "intro": "Tennis player, ML enthusiast"}',
  '{"name": "Rohit Khandagale", "place": "Nanded city, Sinhaghad Road", "waNumber": "919922677787", "email": "khandagalerohit@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0a8ea31bc5b4d20a2a4ccfc39ebaf1a029aba14f91521fce24b9a1ba59e9de7c/16397254336872945257994777555211.jpg", "scribbleSpace": "No time to sharpen the axes in routine. This time will be for personal studies. Plus working out can be added health advantage!", "contribution": "Accounts Finance Taxes Surya namaskar", "intro": "Job - Accounts And Finance"}',
  '{"name": "Anant Pramod Honap", "place": "Wakad Pune", "waNumber": "919923473884", "email": "anantphonap@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8f0372ac751676814642ea71581139f57581ac08614e5d114aa4b41f754aa16e/IMG_20210120_073627.png", "scribbleSpace": "I am artistic in nature. I am hobbyist & change hobbies time to time. I plan too many things but I lack in ordering, consistency & keeping same pace.", "contribution": "I am people\'s person, I lead the team in complex org structure. I am having exposure to stakeholders management & diversified communication", "intro": "Life Explorer, Violinist"}',
  '{"name": "Srividya Thyagarajan", "place": "Balewadi Pune", "waNumber": "917774033934", "email": "srividya.t@gmail.com", "photoLine": "https://api.typeform.com/responses/files/50372477a5272ed924a20cbcf956f7f4fb0a2f47237c9c9de9e3a1cba0fe1044/Thyagarajan_Srividya.jpg", "scribbleSpace": "Love reading, solving puzzles, reading up on current affairs. like to keep fit doing yoga and meditation", "contribution": "I am an IT consultant", "intro": "Aspiring coach"}',
  '{"name": "Yamini. D", "place": "Chennai", "waNumber": "919940112513", "email": "yaminiher@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d7abeca884983ab52711d698867adf0496f8aa6bd7f9178c90f68827001e49c2/20190914_155737.jpg", "scribbleSpace": "Fitness freak and nature lover", "contribution": "Positive thoughts", "intro": "Nature and bird lover"}',
  '{"name": "Tanu gupta", "place": "Bangalore", "waNumber": "917042603175", "email": "tanu.artsbay@gmail.com", "photoLine": "https://api.typeform.com/responses/files/98c7f9f05792b3c8fc5a7d9d79ee05d572b8360923f1330b0c18a4d1fb564e9c/inbound5087688750979114799.png", "scribbleSpace": "I love to paint.", "contribution": "About art", "intro": "Artist"}',
  '{"name": "Shabda Dongaonkar", "place": "Wagholi, Pune", "waNumber": "919970515365", "email": "shabda13@gmail.com", "photoLine": "https://api.typeform.com/responses/files/72e2f9d641f5f6c8e0dba6700ee2b895d5bcfbaf5be93f155d62d95f3fe52f1a/91D952D5_9FD7_425A_9DEF_A1B63FB0C604.jpeg", "scribbleSpace": "My plans for 2022 is to keep myself fit and happy", "contribution": "Helping others , I am more inclined towards literature side , spiritual side, I like to communicate with people", "intro": "IT professional"}',
  '{"name": "Seema N", "place": "Panaji Goa", "waNumber": "919820268434", "email": "bloggingseema@gmail.com", "photoLine": "https://api.typeform.com/responses/files/656f9045bcc65ecb8a1f742c27918125610900b5185745a10f07fa420e3c6fad/IMG_20210303_162204.jpg", "scribbleSpace": "I believe that every life on earth is as important as the other", "contribution": "Benefits of plant based lifestyle", "intro": "Vegan"}',
  '{"name": "Suyash Nagayach", "place": "Bavdhan, Pune", "waNumber": "919890005923", "email": "suyash.nagayach@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9d34718becfbb22cf350392afe2e89078ccc5f860c2c5f16ede299769db9d973/IMG_8329.JPG", "scribbleSpace": "I am avid sports lover, like reading books (mostly non-fiction) and photography is more than merely an interest. The biggest expectation in life is to continuosly become better version of self. Being a night owl all my life, I decided to take up the challenge to wake up early and whoa I have somehow conquered it and discovered about myself more than ever before. Nights are wonderful but believe me Mornings are pure bliss.", "contribution": "I can offer sharing different perspectives from the world of sports. Also, I would like sharing the philosophical poetry during the daily interactions.", "intro": "Pragmatic Believer"}',
  '{"name": "Harsh Ramteke", "place": "Nagpur", "waNumber": "919823671410", "email": "harshramtekeindia@gamil.com", "photoLine": "https://api.typeform.com/responses/files/6f5e62038d361c438aa238c22ab79aa0d069b382986e6d0777ebe66cf212c94c/IMG_20200629_112551.jpg", "scribbleSpace": "Interests- Developing, Designing, sharing life lessons and storytelling.\\n\\nHobbies- Painting, reading stories.\\n\\nPlans- As I love designing and developing i am planing to launch my own design firm.\\n\\nI belive each one of us has something to offer to this world. Your success is not decided by your accomplishments but by how many lives you have touched.", "contribution": "I would be happy to share my life experience, stories that inspires me, and my paintings.", "intro": "Artist"}',
  '{"name": "Ketaki Deshmukh", "place": "Pimple Nilakh", "waNumber": "919923022686", "email": "ketaki7@gmail.com", "photoLine": "https://api.typeform.com/responses/files/efd9f20f358ce02d0505d29cbf4dacea7bd43fce6806b1ef78453fe3aa8800ad/6ED3BD4F_1158_4056_9DF3_55D2F42B3ECE.jpeg", "scribbleSpace": "IT professional so mostly stressful routine. Day ends late due to meetings so Sleep schedule is impacted. My hobbies are Painting, reading, gardening and playing recreational games with kids, friends and family. I wake up between 5.30 to 5.45 am daily, even if I got to bed late. I expect some flexibility during this program practice just in case there are late meetings and I end the day after 10.30pm due to workload and can\\u2019t wake up at 5am", "contribution": "Meditation + Healing practices and experiences", "intro": "Indian Folk Artist"}',
  '{"name": "Poonam Jain", "place": "Aurangabad", "waNumber": "917276024515", "email": "poonam1409jain@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1cbaf18f1c04fd9079790a3e3c6f3d1d60e71ed78e3740894af28cecd39ccf41/DC54FC7F_BFA8_49E2_B70A_63D6EEE1F926.jpeg", "scribbleSpace": "Expectations: want to take control over myself, over my thoughts and have mental peace and try to be consistent in whatever i want to achieve. \\n\\nPlan : Currently in a job from last 1.5 years i am happy there but wanted to build something of my own", "contribution": "I did a recent trip to Jammu Kashmir which was a eye opener for me to step out of my comfort zone and do something different i can share all about that also if in case anyone is having any issue with computer science languages i will try to help\\ud83d\\ude05", "intro": "Software Engineer"}',
  '{"name": "Anusha Yeruva", "place": "Hyd, Telangana", "waNumber": "917036449449", "email": "anshuyeruva@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e45a8e63cc630139af3f0d8f9ef450a6d0f497d93bf5fc0b078d7605cfea4efe/Screenshot_20210521_195038_Photos_2.jpg", "scribbleSpace": "I am intrested in sustainable living. I love nature walks, take fresh air and organic diet, which helps me to be fit and healthy. I am looking forward to know myself and my intrests with the help of this club.", "contribution": "I can discuss on sustainabke living and its future benifit", "intro": "Yoga practitioner and a minimalist"}',
  '{"name": "Rahul Deshpande", "place": "Nigdi Pune", "waNumber": "918308236900", "email": "rahuldeshpande11@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f71fb84ab290f792e99b272cafd95268ef585b42f3889ee2f195eac2b9830fa4/IMG_20211222_155910.jpg", "scribbleSpace": "Not able to spent time on things I like", "contribution": "NA", "intro": "Lifetime learner"}',
  '{"name": "Varun Reddy Sevva", "place": "Kukatpally, Hyderabad", "waNumber": "918500614614", "email": "varun.sevva@iiml.org", "photoLine": "https://api.typeform.com/responses/files/f5f9dce4ca9b2018fe92ebab91d05e4def3a14e211d0809716617caa548a45a2/20210101_163839.jpg", "scribbleSpace": "I work as a HR Professional based out of Gurugram. Married. Occasional Quizzer. US Politics and Cooking are my hobbies. Learning Resume writing in my free time. Planning to host a course on Udemy on Quizzing in the near future. Interested to learn Spanish and know more about Latin American culture.", "contribution": "I can speak about US Political System and US Elections. I can also speak about Esperanto (a language I tried learning recently). I can also run a general quiz if needed. I can also speak about HR in general.", "intro": "Curious Learner"}',
  '{"name": "Pranjal kankariya", "place": "Pune", "waNumber": "918329148603", "email": "pkankariya1512@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/5d222a577eb62b4da18c74bd8fac1f15c8945e625e7182b2935f47a769637f53/B2006646_A60E_4994_B52C_13AC92B23676.jpeg", "scribbleSpace": "I love to paint, read and make miniatures, recycle n restore items", "contribution": "I m still learning", "intro": "Physiotherapist"}',
  '{"name": "Ajinkya Patil", "place": "Connaught Place, Aurangabad", "waNumber": "919421315195", "email": "hsc793@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d7e3299d153d7d2bce3293a11d8f0a5faa434740688fde8fd1794a749ae0a2b9/1526396008597_1531819332186.jpg", "scribbleSpace": "Love cooking. Exploring new cultures through food. Learning through History and tracking International relations & Geopolitics", "contribution": "Nothing specific but will try to give valuable inputs", "intro": "Connoisseur Introvert"}',
  '{"name": "Chetnadeepak", "place": "Chennai", "waNumber": "918870978331", "email": "chetnadeepak24@gmail.com", "photoLine": "https://api.typeform.com/responses/files/15835b93297b3d7dac9e871bc36e35091f89db8d3f50e60eabe42a6dcefc1480/inbound5000761570209175484.jpg", "scribbleSpace": "I want to start a school.", "contribution": "I m talented to give speech.", "intro": "Homemaker"}',
  '{"name": "Amar Osho", "place": "Hazaribagh, Jharkhand", "waNumber": "918971577751", "email": "amar.osho@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9a7bf11477addbf72eccca03634c3fcdd285f10dfa0bd211662eec2c6c03c0e9/IMG_20211225_124415_582.jpg", "scribbleSpace": "I am a happy go lucky person who believes in enjoying the journey rather than reaching the destination. In my free time I read, run, trek, travel, play all kind of indoor and outdoor games. I am a firm believer of - \\"Karma karo, fal ki chinta nahi\\"", "contribution": "Not sure about this. Maybe Yoga or my experience of Vipassana", "intro": "Attentive Student"}',
  '{"name": "Abhishek Anand", "place": "Delhi NCR", "waNumber": "918800373555", "email": "abhishek.x.anand@iiml.org", "photoLine": "https://api.typeform.com/responses/files/9035e146cb216cdb148787bbc0bdb03f58a48f1f14d29c68fd07eeb9ed32074a/Screenshot_20210101_214311_01.jpeg", "scribbleSpace": "Photography, reading. Love to explore the artistic side.", "contribution": "Will think through", "intro": "Love photography."}',
  '{"name": "Dr.Priti More", "place": "Pimle saudagar", "waNumber": "919823230551", "email": "drpritien@gamil.com", "photoLine": "https://api.typeform.com/responses/files/613f64af6ed97a8efd27a5cec1542dd043b60613b14db0afdd76e607feb19b36/IMG_20160229_WA0020.jpg", "scribbleSpace": "I am positive person.", "contribution": ".", "intro": "Fulltime Mother"}',
  '{"name": "Gourav Pattanaik", "place": "Bangalore", "waNumber": "919980983583", "email": "pattanaik.gourav@gmail.com", "photoLine": "https://api.typeform.com/responses/files/207eacb102449e34fca29fb8425d12714558589fc8429e0c7ee85302cff7f5ec/image.jpg", "scribbleSpace": "Want to be more disciplined and focused person", "contribution": "My IT journey", "intro": "Software Professional"}',
  '{"name": "Parul Deshmukh", "place": "Treasure Park, near walvekar lawns", "waNumber": "919545522293", "email": "parul273@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/976df398aa7a2caa37faceb9d0123df37cb3512afcddd0b02c814c85a678be09/105BE85E_6E2F_4454_8A01_7058C292D165.jpeg", "scribbleSpace": "Love to try and learn new spiritual practices.", "contribution": "Painting", "intro": "Illustrator"}',
  '{"name": "Harshal (Shaad)", "place": "Majiwada Thane", "waNumber": "918369288435", "email": "aakarint@rediffmail.com", "photoLine": "https://api.typeform.com/responses/files/da4bc08f74a3e95e627c85b132e6fd85d8dc49a257a32409b8216ba1b6993592/Screenshot_20210721_161300_Photos_2.jpg", "scribbleSpace": "Interested in urdu language, learning the same. Want to be fit till the end. Live and Let Live is the moto of my life.", "contribution": "Knowledge about urdu language, shero shayari and of course about Interior Designing", "intro": "Interior Designer"}',
  '{"name": "Jagdevappa Birajdar", "place": "Solapur", "waNumber": "919545819734", "email": "jagdevbirajdar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9c8f4173995671cf3b9960687d78042f50c38f649ea02d6c173e4c7fd93d8ec1/20210804_182108.jpg", "scribbleSpace": "Reading and yoga", "contribution": "Knowledge about Mathematics", "intro": "Teacher"}',
  '{"name": "Surendra Pimparkar", "place": "Ahmednagar", "waNumber": "917276252855", "email": "sursangam43@gmail.com", "photoLine": "https://api.typeform.com/responses/files/675c007a137fe70f5638677a3de3eccadeac556fe7596a15bfa16383823c34a6/DSC_3427.jpg", "scribbleSpace": "Earlier used to wake up at 4 am for studies & daily routine..... But over period of time lost my habit of early rising...tried many ways but could not get up early..... Thats y joining this course.. So that i can get up early & carry on with my exercise shedule, vocal riyaj of music,do some meditation & make my day wondrful, cherrful", "contribution": "Beimg a medical professional can share experience on health related matter", "intro": "Medical professional"}',
  '{"name": "Kirteeraj Malkar", "place": "Rajarampuri, Kolhapur", "waNumber": "919405550489", "email": "kirteerajmalkar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5ae79c968ab63c7fab1958ae7e9074fa74236d0a05e09b2758160c8f7176ee4a/Screenshot_20200726_120311.png", "scribbleSpace": "i am passionate Developer, interested in sprituality, meditation and finding meaning of life.", "contribution": "I can share my favorite qoutes, I can share some hacks to wake up early & sleep well", "intro": "Developer"}',
  '{"name": "Tirthesh Prajapati", "place": "Dehgam, Gandhinagar", "waNumber": "919591519500", "email": "tirtheshp@gmail.com", "photoLine": "https://api.typeform.com/responses/files/cf865ea9fd7a81513c9e8112784a480a8c782d5720f37e6b32a9809d7d85d540/IMG_20210310_WA0004.jpeg", "scribbleSpace": "Expect life to keep surprising me", "contribution": "Product management and banking", "intro": "Product Manager and a trekker"}',
  '{"name": "Vishesh Sharma", "place": "Bhilwara", "waNumber": "917073901183", "email": "vishesh3108@gmail.com", "photoLine": "https://api.typeform.com/responses/files/54619b0ca56dadafc32bf10abc8d46df23432bcad0d8faa879d96a6d19a56aea/headshot.png", "scribbleSpace": "Hobbies - Reading books, Guitar, Reading Technical Blogs, Public Speaking. In process of breaking mental barriers.\\nI want to develop habit of waking up early every morning. I have done it few times but for some or other reason I stopped it. I want to show myself it\'s just an imaginary mental barrier that I cannot wake up so early. Hence want to be part of this club. Also, my prior experience has shown that it\'s easy to build a habit in association with like minded people.", "contribution": "I am part of Toastmasters club and a fitness enthusiast. Also, I am very keen of reading books. Currently I am reading self-help genre. \\nI am a software developer by profession.", "intro": "Fitness Enthusiast,Toastmaster"}',
  '{"name": "Kulkarni sonal", "place": "Parvati Pune", "waNumber": "919923390543", "email": "sskulkarni012@gmail.com", "photoLine": "https://api.typeform.com/responses/files/18631e17a3fcc0149fea0183164b4a7c5bceaa3b0d04fb8a11e04b549d8286d7/16381621215562895113400167110771.jpg", "scribbleSpace": "Reading,dancing, live each moment of life, accept the things and forgive who hurt you life is more easier", "contribution": "I am working in IDT, good listner and counselor", "intro": "Classical dancer"}',
  '{"name": "Vaishali Pimprikar", "place": "Mumbai", "waNumber": "917738182064", "email": "vaishali.pimprikar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9d52625839a3c13ba05eeedfdf14a72f897458e6a26f96afe24622c578cc9db6/IMG_20210811_051915__01.jpg", "scribbleSpace": "Trying to inculcate good habits", "contribution": "Learnt not to give up", "intro": "Beginner Guitarist"}'
];
var julyData = [
  '{"name": "Sunmeet Taluja Marwaha", "place": "Gurgaon", "waNumber": "919717380111", "email": "sunmeett@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2d93424a31a8418e2dec0c677df22506dfe65a529848785f574bf7ea9d0c9d24/ABO_0566.jpg", "scribbleSpace": "I love cooking, making new formulations in skincare and haircare, love to take workshops, love travelling", "contribution": "My knowledge and my optimism", "intro": "Entrepreneur"}',
  '{"name": "Jeenay Patel", "place": "Ahmedabad Gujarat", "waNumber": "918401114221", "email": "jeenaypatel2003@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9c5484a9254f636545e9c086e9bdf721224b6f2f9c63a128fd9239a22fd5f549/04_Jeenay_Patel.jpg", "scribbleSpace": "I love to explore new things and experience it.", "contribution": "No talent right now", "intro": "Explorer"}',
  '{"name": "Neha Jhamwar", "place": "Sahakarnagar", "waNumber": "919604787541", "email": "ar.nehabangad@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a80e04e553db39ff81ecf2495a401c99b18138fa113dfab7ec767724a52eeb31/IMG_20210222_122628669.jpg", "scribbleSpace": "My vision is to have my own art and design studio", "contribution": "Can\'t think of any", "intro": "Artist Architect"}',
  '{"name": "Janvi Bora", "place": "Yerwada, Pune", "waNumber": "919420920567", "email": "janvibora2004@gmail.com", "photoLine": "https://api.typeform.com/responses/files/46e2bb4481c052f93c39cf2e0f69459a9d3b5dcfc7b26aff70ac63449b24c5cc/20200121_163236.jpg", "scribbleSpace": "I\'m in grade 12, preparing for NEET. I have completed my Masters in Bharatnatyam and also learnt classical music. I love exploring new fields and helping others out.", "contribution": "Music and art", "intro": "Student"}',

  '{"name": "Adki", "place": "Manajari Pune", "waNumber": "919823467393", "email": "amitadki2883@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fc1e91e8f48d679bc048414f10ce0dae2d69e764e7abc93e9d45abe757e7da1f/IMG_20210829_WA0025.jpg", "scribbleSpace": "Cycling is my hobby , expectation are want to be energetic through out the day and develop concentration", "contribution": "management skills", "intro": "Software Engineer"}',
  '{"name": "Janhavi Saraf", "place": "Kothrud pune", "waNumber": "917744978866", "email": "janhavisaraf.23@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c635fc8e2415a179568690c3ad0cf3f88f10ae0efb5f2d9b26e9b4c454496ff8/SAVE_20210926_134718.jpg", "scribbleSpace": "I want to travel the whole world. Love listening to songs and watching movies.", "contribution": "Quilling,paper sketching", "intro": "Student"}',
  '{"name": "Manasi Gadgil", "place": "Andheri Mumbai", "waNumber": "919284932714", "email": "gadgilmanasi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/eb0d1ee4d6bb24f14d62e04e063f3e37ea856e7cf8f42e3ad796a48ed31d7fc9/inbound2724876180844889031.jpg", "scribbleSpace": "I like to sing sketch and drives", "contribution": "Na", "intro": "Banker"}',
  '{"name": "Swati Shilpi", "place": "Mumbai", "waNumber": "918999670813", "email": "swatishilpi63@gmail.com", "photoLine": "https://api.typeform.com/responses/files/da3d96e6a05be45ae3334bf98c14b2563d6dfdaf859774f6351fa09ae8266404/PicsArt_09_04_11.44.59.jpg", "scribbleSpace": "Yy", "contribution": "Positive", "intro": "Doctor"}',
  '{"name": "Hema Iyer", "place": "Thane", "waNumber": "919049148884", "email": "hemaiyerb@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5f621d686f7e9e16bb97080f9d9b92e97fbfcf33739a9d1ced0d61f1e8f9bb16/facebook_1615349494345_6775266845531428237.jpg", "scribbleSpace": "hey 5am\'ers! Excited to be here and really dont know what to expect, so I am open to the activities that this group does.\\nI like doing the usual stuff that most people do - movies, spending time with friends and family(sometimes), Netflix (courtesy Lockdown). \\nI am interested in traveling, working out, going for walks, learning languages (I can speak 4 languages right now & want to increase that count), writing (sometimes)\\nworkwise I am a German language specialist and work in a publishing company - currently online", "contribution": "I\'ll take it as it comes, but can help for German language related stuff", "intro": "Optimistic and Positive"}',
  '{"name": "Thiyagesh Prabu S", "place": "Chennai", "waNumber": "918056274740", "email": "thiyageshprabu@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8b0161a2dfe0ea31ee307087aa0f55cebd629898da7540fd793a6f4ac41b853e/IMG_20210224_203256.jpg", "scribbleSpace": "Chartered accountant. Playing violin, Volunteering with Ngo to teach underprivileged kids", "contribution": "Play violin & offer Personal finance ideas", "intro": "Motivator and Tamil poet"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Dipti Patil", "place": "Wakad pune", "waNumber": "918830397209", "email": "diptipatil3485@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3e696e15ed51eb02e329c971099c4e56362a3931402bdeed3050bccef9aa9b3f/IMG_20210412_172615.jpg", "scribbleSpace": "Cooking", "contribution": "No", "intro": "Doctorate in chemistry"}',
  '{"name": "Amogh Chougule", "place": "Sinhagad Road, Pune", "waNumber": "919890696063", "email": "amoghsc@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9b795eb39bda12832aff97e0eac81e6fd71b6aa92e26c502e1291366cec7bb15/9F0D384C_6BEC_4ED3_BC24_70FD8D1E579F.jpeg", "scribbleSpace": "Jack of many, master of some. Here for growth mindset community & accountability", "contribution": "Can share some insights about personal growth. Areas of interests psychology, human nature, relationships, conflict resolution, creative thinking, embracing discomfort, etc...", "intro": "Curious creative"}',
  '{"name": "Leena Patil", "place": "Mulund Mumbai", "waNumber": "919820383123", "email": "leenapatil@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/601c573570d7a96d5ef3fa854711a7e962bb1b66550958462db9c326eb7a3496/IMG_20210506_WA0045.jpg", "scribbleSpace": "I am a happy  go lucky person. Loves to spead happiness everywhere!", "contribution": "Happy to help always", "intro": "Lawyer!"}',
  '{"name": "Debanjana Sinha", "place": "Bangalore", "waNumber": "919911777199", "email": "debu.piyu@gmail.com", "photoLine": "https://api.typeform.com/responses/files/21b0c903f4bca10a4d35493d84a6cb64fc6900ab0a691f455c3fdc61fd24a4e1/IMG_20190913_184013.jpg", "scribbleSpace": "Stationery, fountain pens, Stoicism, Travelling, Japan", "contribution": "Stoicism, philosophy, digital marketing, fountain pens", "intro": "Memento Mori"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Purvi Savla", "place": "Salisbury Park", "waNumber": "918369285163", "email": "purvisavla2016@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f5dabcfd7971fe0f4e8533e42303d8a027d160656bfef514dde937dd09a86162/IMG_20210626_170049.jpg", "scribbleSpace": "I Struggle to sleep, struggle to wake up. I want to change the tag - I am not a morning person", "contribution": "I can show how to make calming zentangles. No prior drawing skills required.", "intro": "Optimistic Wolf, Enthu Entrepreneur, Single Sassy Mummy"}',
  '{"name": "Gaurangi Patil", "place": "Mundhwa", "waNumber": "917447335879", "email": "gaurangilad2013@gmail.com", "photoLine": "https://api.typeform.com/responses/files/70a4f729b340556cdcc6aeb71eebb22cbbdfce081142752fe3e78ce6c2e44266/IMG_20190817_WA0034.jpg", "scribbleSpace": "Love to meditate....very lazy", "contribution": "Nothing", "intro": "Full time homemaker"}',
  '{"name": "Vidya patil", "place": "Katraj pune", "waNumber": "917350014341", "email": "vidyaspatil7@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f38f9be8bd937a584551b44910d36a4fa9627e71b97ca0ab54aed576a33075fc/IMG_20171120_201411.jpg", "scribbleSpace": "Listening music, doing yoga, travelling,want learn sining", "contribution": "Some yoga and health related messages", "intro": "home Baker"}',
  '{"name": "Prashant Dighe", "place": "Warje, Pune", "waNumber": "919763707605", "email": "prashantdighe1495@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0951a2ddfec967dd37c0d168e9ab263148355baa37fa87329202992b7e2d3a34/IMG_20180909_201003_043.jpg", "scribbleSpace": "Listening music, travelling", "contribution": "Experience", "intro": "Sports"}',
  '{"name": "Prateema", "place": "Pratema bidar", "waNumber": "917019425099", "email": "prateemarajole@gmail.com", "photoLine": "https://api.typeform.com/responses/files/346915eb40b415acfe48f2c20ba50b33c4a40f5d5bac3a909b579c9a81afb9e2/IMG_20181110_WA0003.jpg", "scribbleSpace": "Hobby listening music", "contribution": "Experience", "intro": "Melophile"}',
  '{"name": "Vishal Patil", "place": "Dhankawadi", "waNumber": "919923002980", "email": "vpatil717@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0f9945be0723cf7e786f7e0bfc64f4083d95b033271e9c265a33bbe43b0047d6/IMG_20210629_113322.jpg", "scribbleSpace": "Cricket", "contribution": "Life is beautiful", "intro": "Cricket commentator"}',
  '{"name": "Shrikant Thorat", "place": "Bibwewadi Pune", "waNumber": "919890393742", "email": "shreekant@cathorat.com", "photoLine": "https://api.typeform.com/responses/files/9d0fb328aede1f850c81421f8de55306e713945ca1059bd7c78847380494642f/A84547B3_A65F_4060_9F5B_E120C089E188.jpeg", "scribbleSpace": "I want to always remain fit and want to get successful by inculcating good habits , I believe that every has good knowledge, only those succeed who have the power to keep knowledge updated and have best techniques to use it . Always be apart from crowd don\\u2019t be a part of crowd.", "contribution": "Good inspiring thoughts", "intro": "Learner to do better in life"}',
  '{"name": "Shital Waray", "place": "Kharghar- Navi Mumbai", "waNumber": "919773190283", "email": "shital.waray@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a6ffff7ef932178d77d27bab43b5163081b0b4e514d7ca1fef71c84154910c41/IMG_20200703_182006.jpg", "scribbleSpace": "BE, MBA but chose to be Homemaker, like to paint n travel, proud mother of a son.", "contribution": "Not sure as of now", "intro": "Happy Homemaker"}',
  '{"name": "Rutuja Dhamale", "place": "Pune soon to be Bangalore", "waNumber": "919591574593", "email": "rutuja.dhamale@gmail.com", "photoLine": "https://api.typeform.com/responses/files/7a13c1fa178a67ee544a1491ee961bc3c77946b47195d3caa51264899e918a7f/B1673126_C72C_4E2A_BC57_705B40CCFCDB.jpeg", "scribbleSpace": "I am a striving early riser working in the space of nature education.", "contribution": "Session on human-wildlife conflict", "intro": "Nature educator, naturalist"}',
  '{"name": "Dhananjay Mujumale", "place": "B. T. Kawade Road, Pune", "waNumber": "919765988424", "email": "dhananjay.mujumale@gmail.com", "photoLine": "https://api.typeform.com/responses/files/44ad383a6d2cffd948797ef761fd1c5ee8f22960f25635bf34f7d6b0d5427202/Dhan.JPG", "scribbleSpace": "Software Engineer, Farmer and Yoga Practitioner", "contribution": "My knowledge & experiences about Yoga", "intro": "Yoga Practitioner"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Tanvi sujit kolte", "place": "Dombivali", "waNumber": "919967771914", "email": "tskolte380@gmail.com", "photoLine": "https://api.typeform.com/responses/files/336516cffa3560bc30de4ad205100ff20382af7888a7babe7f72f41a371b3993/IMG_20210318_WA0013.jpg", "scribbleSpace": "Dance", "contribution": "Insurance", "intro": "Classical dancer, yoga teacher"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Siddharth Biniwale", "place": "Ganesh Mala, Sinhagad road Pune", "waNumber": "919405505902", "email": "sakhasiddha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/dbe063adf4940a04a78b4bd017942ef43a6bb0e79fc6c26ba4650772e9bbc491/IMG_20210630_071039.jpg", "scribbleSpace": "I am a Naturalist. Exploring wilderness and understanding ecosystems is a way of life for me. I Birdwatching, wildlife photography interests me a lot. I enjoy listening to the birds and recording bird calls. Currently working on a research project on Avian Bioacuostics (Sounds of birds) in different forest habitats.", "contribution": "a) Tips for wildlife/Bird photography\\n\\nb) DIY tricks to make Bird nests from trash", "intro": "Storyteller, Naturalist"}',
  '{"name": "Brijesh Kumar Bhaiya", "place": "Choolai, Chennai", "waNumber": "919841827966", "email": "bbhaiya@gmail.com", "photoLine": "https://api.typeform.com/responses/files/13dd186e98f4f344c03ea0b7db27493784472cbb657b7f6d87851d9172d749c6/WhatsApp_Image_2021_05_09_at_22.01.48__1_.jpeg", "scribbleSpace": "Travel, Food and exploring new places / avenues are few which i enjoy.", "contribution": "Planning and implementation of an activity.", "intro": "Businessman"}',
  '{"name": "Sanika Surana", "place": "Lasur, Aurangabad", "waNumber": "919404377250", "email": "sanika9surana@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3d1ae8c6b449bb4adb5df7f7b94c5849040c83c1434f51d7992ece28390bc67d/IMG_20210628_011211.jpg", "scribbleSpace": "I am a psychology major student. I love to study. I love planning my day with the help of planners and colourful pens. I am inclined towards every hobby that is creative and healing in nature (doodling, painting, note making, skin care, fitness). I have been working a lot towards maintaining a good balance of things in life. I workout regularly, I eat healthy, I use natural products and now I look forward to better my sleep cycle. I also make a lot of skin care and hair care remedies at home. I am very bubbly and enthusiastic.", "contribution": "Positive psychology tips, doodling, skin care and hair care recipes, experiences, conversations on maintaining a healthy relationship with our loved ones, workouts, conversations about studying effectively.", "intro": "Psychology Student"}',
  '{"name": "prashant saraswat", "place": "raipur chhatisgarh", "waNumber": "917415315833", "email": "saraswat.nitrr@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6b735ceb72e0715819673e2ec6fab58cf555e188c87e66f36320a057fd607c40/IMG_20210721_WA0003.jpg", "scribbleSpace": "interested in reading", "contribution": "I just want to wake up early", "intro": "peace lover"}',
  '{"name": "Gauri Limaye", "place": "Navi Peth Pune", "waNumber": "919371829077", "email": "gauri.gole@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fedaf5f29483c04ed325bd7a9292317499531af60ba81bb28ef79efbd70a1568/IMG_20210110_211420_146.jpg", "scribbleSpace": "I\'m interested in classical music and literature,also interested in language teaching. I love cooking and I am also very talkative person, love lots of people around me", "contribution": "About Marathi grammar, Indian classical music", "intro": "Classical vocalist,teacher"}',
  '{"name": "Vinaya Sambargi", "place": "Mumbai", "waNumber": "919869033624", "email": "vinaya.sambargi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8d51d68f97620ef53299291362bbeb44a7b301fdd284bddc4917554e95456ba4/IMG_20170521_WA0013.jpg", "scribbleSpace": "I like to write, understand human emotions, watch Netflix, Foodie, Bubbling with Creative ideas. Like to live in my space while influencing other\'s", "contribution": "Motivate, Help Wake up, Help to present talent, Help presenting content creatively", "intro": "Exploring life"}',
  '{"name": "Snehal Bari", "place": "Handewadi, Pune", "waNumber": "919284761691", "email": "barisnehal29@gmail.com", "photoLine": "https://api.typeform.com/responses/files/277d683334e71b002528583642b1fa984d6e3811df5c6e5b3eac073a258dfffa/IMG_20210625_175513_087.jpg", "scribbleSpace": "I\'m a yoga enthusiast. Passionate about learning and teaching .  Getting up early at 5 has changed my lifestyle more towards healthy being and it connects me to the nature. Looking forward to stay with this habit lifelong to achieve more than I thought \\ud83d\\ude00.", "contribution": "I can share details of Yogic lifestyle easy to adapt\\ud83d\\udcab\\ud83e\\udd18", "intro": "Yoga Trainer"}',
  '{"name": "Vidya kedari", "place": "Katraj pune", "waNumber": "919850975989", "email": "vidya.ukedari@gmail.com", "photoLine": "https://api.typeform.com/responses/files/803a2ca75ea85ca70aa5f5e45eacf1e79976c8ccce562cdee8129aefc738b15b/20210619_151910.jpg", "scribbleSpace": "Yoga", "contribution": "Yes", "intro": "Yoga practitioner"}',
  '{"name": "Shilpa Gaur", "place": "Makrana Rajasthan", "waNumber": "919794378287", "email": "shilpa020489@gmail.com", "photoLine": "https://api.typeform.com/responses/files/78c34a36457b354f74fc0d396098e0aaa08f3e6d6e7f0c8746ea785584143142/E0D59C16_1EB9_45FC_9C1D_BB89F55CDE93.jpeg", "scribbleSpace": "Reading, Want to get fit and get more done everyday", "contribution": "Functionally I can help in marketing and strategy related things. I work with a gaming studio so anyone looking to understand about this area can connect with me. I can help buddy up with people in micro projects for a week ( like finishing a small online course or book in a week or small sprint like not eating junk etc). I can be a good responsibility partner.", "intro": "Habitual Reader"}',
  '{"name": "Preetee Pushpa-Prakash", "place": "Aundh, Pune", "waNumber": "919422517129", "email": "opreetee@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d9cce749d2e6bef4fe3ad72e7ad78b2d3d0c8a47fd70b7d1d97adc8d7d3a5df7/IMG_20190603_WA0240.jpg", "scribbleSpace": "Reading, writing, learning, wet waste composting, connecting people, following passion", "contribution": "How to put plastic covers on books so that they last longer", "intro": "Co-traveler"}',
  '{"name": "Vikrant Patil", "place": "Kudawale, Dapoli", "waNumber": "918552969377", "email": "vikrant.patil@gmail.com", "photoLine": "https://api.typeform.com/responses/files/be4ea364bedc0b44c3d0200bc3da32197a5ee1b002bcb18a06bf746368fadd93/vikrant.jpeg", "scribbleSpace": "I can say that mostly I live life. During free time I teach programming, sometimes develop small scale software,  read, play music , help people live happy family life (counselling). As part of living life I spend a lot of time talking to my wife and my daughter, cook food, roam around the river, grow food in the small land that we have, maintain my mud house and trees that I have planted and wonder about the secrets of nature around me.", "contribution": "Health, food, yoga, Relationship Counselling", "intro": "A friend"}',
  '{"name": "Vinodh", "place": "Bangalore", "waNumber": "919986025927", "email": "vinodh.hana@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e9bddbd9a213cc61bd6042d2ea0fda949fd9d2beeabd57b3ed5cbccb5bcfbae5/IMG_20210622_112343.jpg", "scribbleSpace": "Digital Wellness Entrepreneur, sports ,book, music, Travel", "contribution": "Sports, wellness,books", "intro": "IT - Sports - Wellness"}',
  '{"name": "Vivek Singrodia", "place": "Bhandup, Mumbai", "waNumber": "919594756234", "email": "viveksingrodia@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3f3b857291e86353b5eb30a3ec86427dd4ff4ca190d27ec55c7971602ba4758e/BG359758_Vivek_Singrodia.jpg", "scribbleSpace": "Likes meeting new people. Keen interest in finance and technology.", "contribution": "Work together on some new idea", "intro": "Silent Thinker"}',
  '{"name": "Shrravane Vuyyuru", "place": "Chennai", "waNumber": "919884983213", "email": "shrravane21@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ab2e8e825bf478608eb71b72409b71667270836ed5594bf4eb113f16f54a851b/C925DFB5_B1C3_45C0_AAF6_EA4C50BB6EEA.jpeg", "scribbleSpace": "Just another happy go lucky girl, finding new ways to become the best version of myself. Also, someone with an appetite for art.", "contribution": "Just looking forward to learn from you guys for now!", "intro": "CA aspirant!"}',
  '{"name": "Neha Sharma", "place": "Kota, Rajasthan", "waNumber": "918000317261", "email": "neha17shrm@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1d3ca1d6516b12934f7bb01afd34d528310ec8df3c78a61df1e115ac0575a1a4/Screenshot_20210630_215149_2.png", "scribbleSpace": "Dancer, painter, roller-skater, hula-hooper, and practicing kindness consciously.", "contribution": "Impromptu short paint /draw session.", "intro": "Abstract painter"}',
  '{"name": "Apurva Patil", "place": "Bhandarkar road", "waNumber": "919822552000", "email": "apurva.patil@spsoftindia.com", "photoLine": "https://api.typeform.com/responses/files/a716e209c56cdd28362a01642de4b56738e4c8d539f1243f128ea6c77afd502e/42EABA11_E919_4F77_9965_D330BCC95626.jpeg", "scribbleSpace": "Nothing to share as such. I am not able figure out", "contribution": "Good in public relation", "intro": "Home maker"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Satyajit Shinde", "place": "Powai, Mumbai", "waNumber": "919869321812", "email": "shinde.satya@gmail.com", "photoLine": "https://api.typeform.com/responses/files/570b5ef08fb1c97e6def281fc96be9bed6490eee96f7dd3c7066f103bc75e60f/20210214_164634.jpg", "scribbleSpace": "IntereseBirding, Wildlife Photography", "contribution": "Na", "intro": "Bird Photographer"}',
  '{"name": "Rashmi chand", "place": "Powai Mumbai", "waNumber": "918879248582", "email": "rashmichand17@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e155c46a6000667b760c83639dca9bc56ac3ecde67fc9c4137d7328e0ee11095/IMG_20210623_WA0027__01__01__01.jpg", "scribbleSpace": "Writing, reading, crafting and yoga", "contribution": "Not sure", "intro": "Content writer and blogger"}',
  '{"name": "Dr Seema Aiwale", "place": "Pune", "waNumber": "919665460160", "email": "seemasaiwale345@gmail.com", "photoLine": "https://api.typeform.com/responses/files/56629981ae64746e65a7835b3a89c200a2c1a3ca6c1f5662261b2f9292d03997/Screenshot_20210722_230155.jpg", "scribbleSpace": "M health n fitness lover , help people in right way through right nutrition diet spiritual,mental, financial n physical well being . Most interested in Guiding women through women empowerment..", "contribution": "Health ,Nutrition,", "intro": "Gyanecologist SKin and Nutriti"}',
  '{"name": "Prakrthi A N", "place": "Mangalore", "waNumber": "916361696276", "email": "prakrthian@gmail.com", "photoLine": "https://api.typeform.com/responses/files/dc53499818c58c0e54332dc8c0dcbd6f595c5568f49e3665f6568e728a1f5bd9/Prakrthi_Alankaru_Narayana.jpg", "scribbleSpace": "Yoga, cooking", "contribution": "About nanoresearch, nutrition-diet", "intro": "Research student"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Shwetha Kamath", "place": "Thane", "waNumber": "919637864979", "email": "baliga81@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ce416586f2f40a85ec8c235384ed9592546fddec83f67bbe09096c7aaf1a8e0e/Screenshot_20210829_211702.jpg", "scribbleSpace": "I am an optimistic person with a positive vigor. I cherish my work, my family and my friends.", "contribution": "I love to make people smile and I am not an early riser. This entire experience would be a challenge for me.", "intro": "Teaching Professional"}',
  '{"name": "Nikita Auti", "place": "Kothrud Pune", "waNumber": "919730044825", "email": "nikitaauti0410@gmail.com", "photoLine": "https://api.typeform.com/responses/files/72588357f62e09c57aa6e2abf500ab9df9e0b4526454d8a90f4c8e620795ec17/Snapchat_81252179.jpg", "scribbleSpace": "I love listening lectures related to life \\nI always wanna go out and visiting new places", "contribution": "Im teakwondow player", "intro": "Student"}',
  '{"name": "Kalpita Vishwarup bose", "place": "Versova, mumbai", "waNumber": "919004688668", "email": "kalpitavbose@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b92b9a5e229c76692579f437fc660378b2303507a4ef2a7122a07fa4ba295703/6C75D3B3_DB31_4A1F_8094_F381609597AF.jpeg", "scribbleSpace": "I love fitness in all forms mostly weights , yoga , dancing, calisthenics. Love music and singing but currently since six months can\\u2019t due to acid reflux ans stress . Love art creativity in any form. Started my own company 6 yrs back. It\\u2019s been good but faces a difficult time due to lockdown. Also recovering from an Alcoholism led marriage ans hence separation and a complicated relationship .\\nLove nature, believe in natural wisdom and like meeting people. Been working for 24 yrs and still love working. I have a lots of friends and social connections but I have a new fear of being alone. I also mourn the loss of a child.", "contribution": "I can share strategic knowledge about building brands, content creation ,taking care of animals/pets , gardening, yoga, dance too to a certain extent.", "intro": "Graphic designer"}',
  '{"name": "Sunil Wakle", "place": "Wakad pune", "waNumber": "919922446310", "email": "sunilwakle@gmail.com", "photoLine": "https://api.typeform.com/responses/files/34fdd106a5da8bacc664a622710e0944c6c51adcdd03be6aa39c6de4f2abdf60/IMG_20210815_104501.jpg", "scribbleSpace": "Sports, new learning, meditation", "contribution": "Sports", "intro": "Want to be marathon runner"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Dr. Archana Jeevan Shinde", "place": "Nanded city, Pune", "waNumber": "919923797018", "email": "drarchanajshinde@gmail.com", "photoLine": "https://api.typeform.com/responses/files/85e988cb5bdc20bda92be5b944ce6bdf2de953e43d0aea5ba06ee024e428ccff/IMG_20210523_110556.jpg", "scribbleSpace": "Skin and nutrition are my interest areas am exploring. My mission is to help millions of people lead healthy life.", "contribution": "Wellness coach for many lifestyle disorders like diabetes, PCOD, thyroid etc.", "intro": "Entrepreneur"}',
  '{"name": "Aparna Deshpande", "place": "Baner, Pune", "waNumber": "919665011444", "email": "aparnasanglikar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fe0a267de8b41fe7063adab94830d27eccebd9561825c16ad93d06031be5670a/454F1613_6844_464C_86AC_913C2916FB52.jpeg", "scribbleSpace": "I am a voracious reader and love listening to classical Indian music. I hope this club helps get some \\u2018me time\\u2019!", "contribution": "I enjoy working with my hands and often find myself working with my 5 yr old on various crafting projects. I can share ideas about the same along with book recommendations.", "intro": "Lawyer"}',
  '{"name": "Preetee", "place": "Aundh, Pune", "waNumber": "919422517129", "email": "opreetee@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f0089291d000657537ce06b45abe4469980c244e5feab05e46c6af4149206653/WhatsApp_Image_2021_06_26_at_23.20.25.jpeg", "scribbleSpace": "I am a book. I let the reader read me. Contents of the book: Meditation, Yog, Reading, Writing, Biodiversity, Gardening, Education, Parenting, Mindfulness, Water conservation, Soil conservation, Environment Education", "contribution": "Eco friendly parenting, Screen free parenting, Eating habits, Terrace Gardening, Lazy composting", "intro": "Nature observer"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Anuja Deokar", "place": "ABC Pune", "waNumber": "919604304525", "email": "anudeokar24@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ec6f91b9a1926ded4d0271b5db1635581aaf05df4a30370414f73e4dac05d25f/IMG_0202__1_.JPG", "scribbleSpace": "interests", "contribution": "Finance related", "intro": "Always Confused"}',
  '{"name": "Sonali Tambat", "place": "Panvel", "waNumber": "919975329474", "email": "sonali.tambat21@gmail.com", "photoLine": "https://api.typeform.com/responses/files/759811ca61efa49afa96626f563b8caf5f90d66df06e5a2b972c64c4a84babb0/IMG_20210815_204628.jpg", "scribbleSpace": "Happy to go person", "contribution": "Not specific", "intro": "Professional Social Worker"}',
  '{"name": "Prachi Bogam", "place": "Harigram village, new panvel", "waNumber": "918655573501", "email": "prachi.bogam@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f9f3572922c1d4660a1677d2cf2af6d957942cd3634303fa1199a2576f99e47c/IMG_20210728_122321.jpg", "scribbleSpace": "I am home maker now and since not doing any outside job whenever get time I got into the habbit of watching mobile so wanted to get rid of that and persue new habits of reading, walking etc.", "contribution": "I work in the field of early childhood education and language development.", "intro": "Eucationist"}',
  '{"name": "Shaila Patil", "place": "Satara", "waNumber": "919764663030", "email": "shailapatil306@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1a2f92494026dcf1a20243f3ca607bd823bad5668d098a07946b2a6d9464b0ff/IMG_20210726_WA0015.jpg", "scribbleSpace": "Interested in gardening.Love to spend time with nature Want to lead a healthy life.", "contribution": "NA", "intro": "Teacher"}',
  '{"name": "Sunil Makare", "place": "Panvel", "waNumber": "919833571502", "email": "sunil.makare@gmail.com", "photoLine": "https://api.typeform.com/responses/files/458838a83388aecb016711fbb52e3435ec617844781c4e5e144db929048794b6/IMG_20210821_WA0000.jpg", "scribbleSpace": "Taking up this new challenge of early rising to make it no more a challenge.", "contribution": "Can share about own life experiences whenever fit in.", "intro": "Development Professional"}',
  '{"name": "Varsha Reddy Durgempudi", "place": "Saidabad, Hyderabad", "waNumber": "919963402110", "email": "varshadurgempudi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/019ce4543ffee5445f41dd5be0ff6320a1b59f56f256b6f8d43cf42a75bd739a/2019_01_18_09_47_48_791.jpg", "scribbleSpace": "Going to start my PhD in few months and thus want to reset my circadian rhythm by waking up at 5AM", "contribution": "Stubbornness maybe", "intro": "Caffeine addict"}',
  '{"name": "Nikita Garg", "place": "Gurgaon", "waNumber": "919810433924", "email": "garg.nikki1990@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0ac0b192da6a90cde8b22e448e1f22f64d26c48bb307dfb7743f598329762b9b/IMG_4160.jpg", "scribbleSpace": "I am CA by profession, working with Deloitte. I love to do baking and read books in my free time.", "contribution": "I have knowledge in Company\'s audits, making chocolates", "intro": "Chartered Accountant"}',
  '{"name": "Shravan K", "place": "Bangalore", "waNumber": "918792857289", "email": "ksshravan667@gmail.com", "photoLine": "https://api.typeform.com/responses/files/57f53ad8353df912f1901e58765778a9b4f2128e088136c62e422ce68ad52a55/ShravanPhoto_.jpeg", "scribbleSpace": "Yoga, coding, morning walk", "contribution": "Coding", "intro": "Yoga practitioner"}',
  '{"name": "Mahesh Todkar", "place": "Kothrud Pune", "waNumber": "919970272657", "email": "maheshtodkar86@gmail.com", "photoLine": "https://api.typeform.com/responses/files/514f4413691db6bb1007301e785d3c7f5565d5b080fbb32cd319eaf205356a41/Mahesh_Todkar_ID_Photo.png", "scribbleSpace": "I wanted to make habit of early morning wakeup.... As an IT professional currently facing lot of issues with Health and work life balance....", "contribution": "Software Development", "intro": "IT Professional"}',
  '{"name": "Chenchu", "place": "Thiruvallur Tamilnadu", "waNumber": "919080075938", "email": "chenchu1999@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5a9d83ac2a5cae80001a380ca60bcaec88e065cd7f1f85b1899f87eb679e7880/2017018019_1.jpg", "scribbleSpace": "Interested in reading books", "contribution": "I am good at singing", "intro": "I am a student"}',
  '{"name": "Vijay Chougale", "place": "Kolhapur", "waNumber": "919604086304", "email": "vijaychougale987@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d88784855bea8e878f421485cd0b9860bdb3843a9fc1b552691bcdffe930c047/IMG20210418070936.jpg", "scribbleSpace": "By profession I am an engineer. Likewise I am very curious about knowing engineering of life. I want to go deeper into spirituality.", "contribution": "Meditate every day", "intro": "Spiritual"}',
  '{"name": "Rupesh M", "place": "HSR Layout Bengaluru", "waNumber": "918123811015", "email": "rupesh.mokshagundam@gmail.com", "photoLine": "https://api.typeform.com/responses/files/04e764f69c704081c7a4fb67c6387a3b911d199ae2c8e5b85708819ab5c7a8b3/DSC_1663__1_.JPG", "scribbleSpace": "technology,Data,Chess", "contribution": "my work experiences", "intro": "Software Engineer"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Sharvari Patil", "place": "Baner Pune", "waNumber": "919766039388", "email": "sharvari.p@gmail.com", "photoLine": "https://api.typeform.com/responses/files/58eab976724715c3981c2f1b0805181e100b50bbae4f06ce143f1dd8a86226f8/img_4_1626544121665.jpg", "scribbleSpace": "I love exploring new places, meeting new people & spreading positivity.", "contribution": "My travelling experiences, my songs on keyboard & my short poems / shayris.", "intro": "Wanderlust & Photographer"}',
  '{"name": "deepali chaubal", "place": "Bangkok", "waNumber": "66814589440", "email": "samdeep45@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a3a343e571255e103b4b5160fad28a78ef98a5ce67c8d7b59b2617b03e4a4e4b/WhatsApp_Image_2021_07_30_at_3.06.07_PM.jpeg", "scribbleSpace": "I am a people\'s person. I love travelling & storytelling", "contribution": "Story telling", "intro": "A Story Teller"}',
  '{"name": "Pranav Patil", "place": "Parvati Pune", "waNumber": "919822322184", "email": "pensafe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fc949309ee0e158a2e044e0a403d861dcd7e48f6f6869dc5f0ee51124395bd9f/Screenshot_20210624_162326__01.jpg", "scribbleSpace": "Psychologist body-builder, INFJ, Counsellor-Mentor-Advisor \\ud83d\\ude07, Scientist, Astrology-curious, Work-out-holic \\ud83d\\udcaa, Nasha-positive person", "contribution": "1. How to be happy 2. Micro- workouts at home 3. Make good tea 4. Socializing tips 5. Tinker on piano", "intro": "Armwrestling enthusiast"}',
  '{"name": "Seema Kalantri", "place": "Bopal Ahmedabad", "waNumber": "919586499988", "email": "srkalantri@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/fadd501212aa1930e2e04bc7b35404df19751a1d6ab937ef24a8398715195934/IMG_20201212_082556.jpg", "scribbleSpace": "Learner", "contribution": "Good listening Ears", "intro": "Software Professional"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Renu Shirokha", "place": "Mumbai", "waNumber": "919004087387", "email": "rshirokha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6f0139a6cdcff3e995d15abce2ab2cf40095526fd4c2e1819956cce7af0ccb58/603BFBEB_4733_4023_B4C8_C84F32DD982A.jpeg", "scribbleSpace": "I m architect as well as artist. My hobbies are reading, travelling and playing with my dog. My plans are concentrate my growth in self development as much as possible got this year.. looking forward to meet you all soon", "contribution": "Well, i would like share the more we look within ourself .. everything falls on it places on outside", "intro": "Architect & artist"}',
  '{"name": "Anshul goel", "place": "New Delhi", "waNumber": "918178738858", "email": "adorable.anshul@gmail.com", "photoLine": "https://api.typeform.com/responses/files/70b79a871836020ba7ae48e251db8ed2f13557284109b582342d7c78e507e299/IMG_20210928_183622.jpg", "scribbleSpace": "Gardening Baking Teaching", "contribution": "Gardening", "intro": "Technical product owner"}',
  '{"name": "Balaji Mankari", "place": "Chandra Layout, Banglore", "waNumber": "917275656561", "email": "balajimankari2015@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a2d7707cca80b159c2d0b7a706e51c964d67559f3f0a70a7202ad6a81a7cdcf4/A8332870_CAA5_4261_842C_CF5EDF046D77.jpeg", "scribbleSpace": "Road trips, trekking, Farming, Teaching, Writing journals, Bike and Car enthusiast, Interested in International politics and relations.", "contribution": "Mediation, and about international politics", "intro": "Voyager"}',
  '{"name": "Rajni Vishwakarma", "place": "Gurgaon", "waNumber": "919718870902", "email": "rajnivishwa1982@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5120233d6a1aebeaf93f992bffcfdafa52f2f6d2cdc8e0908f479a8375df1222/IMG_20191108_184204.jpg", "scribbleSpace": "Single mom", "contribution": "Beauty and wellness", "intro": "Beauty expert"}',
  '{"name": "Shankar Krishnamoorthy", "place": "Puttaparthi Anantapur District", "waNumber": "919004699819", "email": "shankar_k123@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/ba4d69dc14611440bf60a912dbd4d77500a3b54b06457478d1252854ffd52d30/IMG_20210311_WA0115.jpg", "scribbleSpace": "Spiritual Books,Music", "contribution": "Whistling", "intro": "Spiritual Aspirant"}',
  '{"name": "Ramya Duraisingh", "place": "Bangalore", "waNumber": "917738851113", "email": "ramyaduraisingh@gmail.com", "photoLine": "https://api.typeform.com/responses/files/7c2cc79ec8132070790a1042ca1c7939ef41fe93e8b5dbb815b2101c87b847db/Screenshot_2017_03_06_19_00_42_867_com.miui.home.png", "scribbleSpace": "Trying to form new habits", "contribution": "Nothing as of now", "intro": "Working professional"}',
  '{"name": "Nancy joshi", "place": "Jhansi", "waNumber": "919404727376", "email": "nanjoshi1@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9ad9ca9a0bffd504bb3414466697ec11752df41d73085dcdf6e73eb8c64cc8ed/IMG_20211013_213736.jpg", "scribbleSpace": "I enjoy music", "contribution": "Cooking", "intro": "Teacher"}',
  '{"name": "Priyanka Singh", "place": "Delhi", "waNumber": "918130826975", "email": "priyankasingh.dpd@gmail.com", "photoLine": "https://api.typeform.com/responses/files/69de8a402ab6b76e19df932fb98a4f7320f1583348496470270a9d1bdd54b2cf/IMG_20200724_WA0028.jpg", "scribbleSpace": "Plan to visit whole India before crossing 40", "contribution": "Have not much to share..", "intro": "Government servant"}',
  '{"name": "Manisha Bhujbal", "place": "Balajinagar pune", "waNumber": "918208503607", "email": "manishaabhujbal882@gmail.com", "photoLine": "https://api.typeform.com/responses/files/14a2a1a878e4d7f6356eb3187b0cb43f40c8f58882b3541a7c3c6ac5f171fc34/IMG_20210615_122942.jpg", "scribbleSpace": "I like music and reading and help to people", "contribution": "Yes", "intro": "I am teachar"}',
  '{"name": "Prashant Pednekar", "place": "Nigdi Pune", "waNumber": "918600140600", "email": "prashantped@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6df1ab4af9d4801dd806aacb1de9990e1dec442e2df823c9915c75bd034c3577/FB_IMG_1601687035215.jpg", "scribbleSpace": "Learner and project manager", "contribution": "Project management training ,", "intro": "Constant learner"}',
  '{"name": "Saurabh Agrawal", "place": "Raipur", "waNumber": "918602100608", "email": "srbhagrawal14@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f17ef5b8bb5576ea42db3a857e302fe35ddefae1ca399ed64f4e82ed5795e61d/PicsArt_06_14_11.51.07.jpg", "scribbleSpace": "Progressing to achieve something. Banker by Profession. Like listening music, solving problems.", "contribution": "I am novice to share anything.", "intro": "Banker, reader"}',
  '{"name": "Kartik Kinge", "place": "Wardha", "waNumber": "918975383155", "email": "kingekartik@gmail.com", "photoLine": "https://api.typeform.com/responses/files/634ecaae1fae40f15714c5051fc3aadd932b81b9bedb3f59ad2c37d73c259dc6/IMG_6475.JPG", "scribbleSpace": "Engineer by profession, Traveler at heart. I am always to for coffee to discuss ideas and life.", "contribution": "I am AR VR developer. I can help people understand about these emerging technologies", "intro": "Software Developer"}',
  '{"name": "Aalok Bhuyar", "place": "Nagpur", "waNumber": "919022918347", "email": "aalokbhuyar211@gmail.com", "photoLine": "https://api.typeform.com/responses/files/919f7a88d47a0cad154c89325ae371c44ce37da2da9ade3f03e4503b61d3053b/DP.jpeg", "scribbleSpace": "Student at IIIT Allahabad\\nexplorer", "contribution": "life experiences\\nmy perspective towards things", "intro": "Tech enthusiast"}',
  '{"name": "Mehak Karda", "place": "Sigra Varanasi", "waNumber": "919819427080", "email": "mahakkarda9@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c041863a51fffd0ac6b8f93fddfcc185538659ad52459e1d5a9388a2fff50db6/IMG20210425200224_1.png", "scribbleSpace": "Hi there! How are you ? If \\nI talk about my interests and hobbies, then I love to travel, meet new people, do yoga and meditation, read self help books. I am a nature lover, animal lover and would love to work for social causes. I aspire to become a fashion designer and work in the field of creativity and imagination. I expect that the 5am club is going to bring out the best in all of us. Currently, I am into a phase of analysing and deciding for myself about what are the next steps...", "contribution": "I think I can share few meditation techniques and share my experiences", "intro": "Optimistic bird"}',
  '{"name": "Padmanabhan", "place": "Bangalore", "waNumber": "919900799744", "email": "sapnapaddy@gmail.com", "photoLine": "https://api.typeform.com/responses/files/67a19e8e5f89b82f5e544def0ce40c0a58e89164d382d6a0d81fcb2d21c6532d/IMG_20211127_212336.jpg", "scribbleSpace": "I love to think, i love some me time , i love nature ! I want to access my best potential and stop giving excuses to wake up early!", "contribution": "I could try comedy ,or a bit of singing or i could lead guided meditations or facilitate a quick warm up yoga session.", "intro": "Yoga practitioner"}',
  '{"name": "Manisha J. Hinhoriya", "place": "Aurangabad Maharashtra", "waNumber": "917038958300", "email": "jivisayoga@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f8d8d875b6b54a81fc9315b6e98d67946007881955597c760df9ddd7481c6633/IMG_20210810_101301.jpg", "scribbleSpace": "To teach yoga", "contribution": "I\'m QCI level 3 yoga teacher n registered yoga alliance teacher", "intro": "Yoga Practitioner"}',
  '{"name": "Snehal Gandhi", "place": "Wagholi Pune", "waNumber": "918698093357", "email": "snehal.gandhi10dec@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2ef6d546ef7781b840e3bc05850d8a411b406ed0caf485bdf7a20dbcbb906cee/4D873851_925B_4141_AA99_370CF69CCCEF.jpeg", "scribbleSpace": "Dancing listening to soft music", "contribution": "I m an introvert person I don\\u2019t like to talk to unknown people that\\u2019s why I m participating in 5am club to come out off my comfortzone", "intro": "Photographer"}',
  '{"name": "Vaibhav", "place": "Kolhapur", "waNumber": "919579579680", "email": "vaibhav.h21@gmail.com", "photoLine": "https://api.typeform.com/responses/files/37cbc71c3991e9695caf080ddf79eef81ba1d40a2bf9b8484d0486f6feb17ba2/IMG_20191012_152033.jpg", "scribbleSpace": "Traveling, wild life lover...listening music...dancing...sports..", "contribution": "Jocking, making people laugh", "intro": "Nothing from above"}',
  '{"name": "Pragya", "place": "Pune", "waNumber": "918368154614", "email": "pragya891997@gmail.com", "photoLine": "https://api.typeform.com/responses/files/52527ee6225a3723f16b11ee9ce740ce043232421aa03aa4526e145d2801373d/IMG_20211128_WA0007.jpg", "scribbleSpace": "I want to become an independent & happy person....\\nLove to dance occasionaly....\\nJoining this club to become an early riser & to bring some poaitive change in my life & personality", "contribution": "Nothing specific as of now", "intro": "Medical student.."}',
  '{"name": "Prajakta Advant", "place": "Bavdhan Pune", "waNumber": "919850891130", "email": "prajjoshi19@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8a66fd69e3375b2390e6d19e60d1c8cfc43ea92f630d8094767ebfcaf5a0bc45/DECED0DE_02FE_47DE_AA2C_265A77557D08.jpeg", "scribbleSpace": "Love to listen music, travel", "contribution": "NA", "intro": "Travel enthusiast"}',
  '{"name": "Siva Ranjan", "place": "Bangalore", "waNumber": "919986034737", "email": "sivaranjan.maharana@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b6519849ffac06428a52a085af2a01a8765b4255c6e62c2d3653a9458eafcff6/inbound8731312006887089191.jpg", "scribbleSpace": "I am an introvert. My idea of recreation is painting, biking, gaming, cooking. I am an UX Professional.", "contribution": "Paintin, Photography, Cooking", "intro": "Paint and Photography"}',
  '{"name": "Hema", "place": "Mumbai", "waNumber": "919004261133", "email": "hema.sagare@gmail.com", "photoLine": "https://api.typeform.com/responses/files/248d7e058a125dd56d1d55f9cf223ca956e998861c278714ec463bf5e3392721/PXL_20210513_135425411.jpg", "scribbleSpace": "Hilarious hema as I call myself! Loves to watch birds anywhere they appear:) loves to connect people from different fields..", "contribution": "I can talk about what birds did to me.", "intro": "Bird enthusiast!"}',
  '{"name": "Sandeep Karande", "place": "Brahmand, Thane", "waNumber": "919619903224", "email": "karandesandeep@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f4b2a91f5b54c36292644395990d8212b5d288584c594bc2c2af88fe5003968a/DSC_9324.jpg", "scribbleSpace": "I enjoy trekking, bikes rides and nature conservation", "contribution": "How treks can help you explore a new self.", "intro": "Nature Lover"}',
  '{"name": "Debasree Dey", "place": "Baner, Pune", "waNumber": "wa.me/+918380078981", "email": "divinedanica@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ffe32615087816e2c9e28cde633bd10c81346b39976e743599cf1e094a2b9a80/778DD2D9_23EC_4A18_B78A_0977820C01F6.jpeg", "scribbleSpace": "An artist who creates beautiful things everyday.\\nQuit the rat race (worked in IT for more than a decade).\\nLove reading books.\\nAddicted to personal growth.\\nAdrenaline junkie, backpacker (25 countries), trekking... love nature..\\nKnow me: debslilarts.com/about", "contribution": "None at 5 AM", "intro": "Creative Entrepreneur"}',
  '{"name": "Gauri", "place": "Bangalore", "waNumber": "917776964261", "email": "gaurivshete@gmail.com", "photoLine": "https://api.typeform.com/responses/files/38201bf3dc88005aa89e242f2b05d50a4c38030adbeeab150084f599b7079fee/inbound2884876785433452472.jpg", "scribbleSpace": ".", "contribution": ".", "intro": "Perpetual learner"}',
  '{"name": "Sachin Sharma", "place": "Kharadi Pune", "waNumber": "919860604507", "email": "bestofsachin@gmail.com", "photoLine": "https://api.typeform.com/responses/files/21aa552e4ecf2be70bcac857da0de38290ae4508db0e113e9997c88562b8cb6a/72F8F4CB_28D2_4FEE_8AB9_80EA77A47E96.jpeg", "scribbleSpace": "An artist, trying to set his routine to learn and achieve more. Currently waking up late, then office from morning till eve, so trying to set some timings to live more!", "contribution": "I\\u2019m a writer, poet, singer and handpan player.", "intro": "Writer, Singer"}',
  '{"name": "Shivani Chavan", "place": "Versova Mumbai and Pratapganj peth Satara. I trav l often", "waNumber": "918591110815", "email": "shivani.chavan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/665a3072a7336cb513bd0ed96f90d6fd41dc3449237398c7eac31e18618972d4/IMG_20211119_WA0000.jpg", "scribbleSpace": "I love almost everything that life has to offer- I read, I run, I cook sometimes, I like eating out, I like watching movies, I like treks, I love morning walks, I like almost everything except watching news!", "contribution": "I have plenty of stories to share. I have attended international camp when I was in school, I left a high paying job to start a business, there are many more. I have won awards for elocution, so I can share tips on it. The list is long. Professionally, I am an expert in lean operations. I headed business excellence for Nerolac.", "intro": "Entrepreneur"}',
  '{"name": "Swati shilpi", "place": "Mumbai", "waNumber": "918999670813", "email": "swatishilpi63@gmail.com", "photoLine": "https://api.typeform.com/responses/files/04040a56bee3b3066efc9590df32405e758bce70d031ab223f966698f01bb3a2/PicsArt_11_02_02.44.11.jpg", "scribbleSpace": "Trying to get back on track since covid\\ud83d\\ude05", "contribution": "Kind of into painting?!", "intro": "Doctor (intern)"}',
  '{"name": "Ajay Kumar Dadi", "place": "Warangal", "waNumber": "918897755822", "email": "ajaykumar.dadi2103@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c4bb585c2f8a2ae2dce8a6d8191dffcafded5972df421a8b48dd11dea457fd4a/IMG_20191124_180757.jpg", "scribbleSpace": "I love thriller and horror movies. Looking for excitement in every aspect of my life.", "contribution": "How to make our lives easy", "intro": "Natural lover"}',
  '{"name": "Nandini Gulhane", "place": "Shashtri Nagar , Nagpur", "waNumber": "918080477075", "email": "nandini.gulhane10@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d4878dd11e9f41fe44bcf8aeeb84828e8c1d53c67aa43f69201d04ceb27a5d6d/20210922_231456.jpg", "scribbleSpace": "Hobbies: love to dance\\nIssues: stressed about career and not able to focus, concentrate on work.", "contribution": "Experiences of this boot camp", "intro": "Enthusiastic Nands"}',
  '{"name": "Ragini", "place": "Noida", "waNumber": "919999673653", "email": "ragini0407@gmail.com", "photoLine": "https://api.typeform.com/responses/files/33b08f3f79218081ef09eff580a8d5b3fa6a3c0ed3327ea7f1ecfb0764f0e661/8C3A2E52_22D6_4E33_A0C7_CBD73C36986E.jpeg", "scribbleSpace": "Love to listen music , writing articles and travelling", "contribution": "Travelling experiences , creative style for events", "intro": "Creative thinker"}',
  '{"name": "Bhoomica Asnani", "place": "Thane Mumbai", "waNumber": "919987540067", "email": "bhoomica.asnani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/7e10c78d7ac5ecbec1e44dfefd08dd7e754012345f5fc9ad3a2c6ac68756cf93/IMG_20210328_190020.jpg", "scribbleSpace": "I am an academician by profession. I like meeting new people and learn more about the joys of being alive.", "contribution": "Experiences of being an academician. Difficult teenagers, lazy youngsters, helicopter parents", "intro": "Seasoned Woman"}',
  '{"name": "Sony Pardeshi", "place": "DSK Vishwa Pune", "waNumber": "919823727376", "email": "sony.rp@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2dda89870504b404828535b1fc1d0d85af1b4d18bdabbe0731da6800a428282c/inbound8776878960993373812.jpg", "scribbleSpace": "Movies, Indian classical instrumental, hiking etc", "contribution": "None", "intro": "Chess, Books, Graphology"}',
  '{"name": "Varsha Bhat", "place": "Kothrud Pune", "waNumber": "918007811906", "email": "varshabhat2693@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d41e18f5d88ba90e13fac879237a03c386b1040f5761e0edf9953efee3749d89/Screenshot_20211130_170959_2.jpg", "scribbleSpace": "Would like to wake up to workout and meditate every day", "contribution": "Na", "intro": "Analytics Analyst"}',
  '{"name": "Rashmi S. Tupe", "place": "Narhe, Pune", "waNumber": "919922263074", "email": "rashmitupe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5f3e61bbde89b3d74910d7fb104413ff7bce9021ea5d27c9bb7e62d0bfe7370e/20210204_210048.jpg", "scribbleSpace": "Travelling, reading etc.", "contribution": "Simple and wonderful life", "intro": "Academician"}',
  '{"name": "Jyoti Dubole", "place": "Bhayandar (w)", "waNumber": "917977372009", "email": "jyotishindedubole12345@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0d7b91eb0ec5621bf02ad8b1f461c7c0d08824ab05f3c0214ef9b09d392ea6ce/Screenshot_2021_04_02_15_35_00_35.png", "scribbleSpace": "I am a teacher and I have done MA. Bed .  In marathi .", "contribution": "Can\'t say for sure", "intro": "I am a teacher"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Rahul Sharma", "place": "Sahakarnagar, Pune", "waNumber": "918390876388", "email": "rahul.symantec@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a21228e6f6004fdf5eb652c945c2ae0f1a37b252a23d6e0235997fbf7862a575/image.jpg", "scribbleSpace": "Have been working in IT field field since 15 years out of which I was working in Night shift (9:30 PM - 6:30 AM) for last 3 years. This seems to have messed up my body clock. While I am back to regular working hours  now, my expectation is to get into the habit of waking early and going to bed early.", "contribution": "Managing personal finances.", "intro": "People Leader"}',
  '{"name": "Mrudula Vaidya", "place": "Sahakarnagar No1, Pune", "waNumber": "919860554912", "email": "mrudulak1981@gmail.com", "photoLine": "https://api.typeform.com/responses/files/665e607bb0d5930ce3e4afb880e70e44732cba9a2ed863d243b2e750f15a52d1/Mrudula.jpeg", "scribbleSpace": "My hobby : Reading", "contribution": "Nothing Special...", "intro": "Software Developer"}',
  '{"name": "Shrutika", "place": "Baner, Pune", "waNumber": "919970514512", "email": "shrutikacadmane@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ca8ec3c945df7ca81f31d485aef11677bb2ec9b5070df62b80fc190b1bfe6966/inbound9118987176709980952.jpg", "scribbleSpace": "By profression I am an IT engineer by interest I am an artist.", "contribution": "Anybody can paint, just try once and then u will be in love with colours", "intro": "Artist"}',
  '{"name": "Pallavi milin khairnar", "place": "Vadodara Gujarat", "waNumber": "919408081443", "email": "pallunam@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a6652af5799c6c65f1431ab451be7c5fdee8e1067a1ba611b2d6d92b631759e6/20211118_221402.jpg", "scribbleSpace": "Music,reading", "contribution": "Recipes,teaching experience", "intro": "Self employed"}',
  '{"name": "Dr Archna Sahay", "place": "Katraj, pune", "waNumber": "917218250608", "email": "sahayarchna@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f1b91e6205e428c1a4c5b7a49c63688a6fee0b5f55543ac6c0329b882f448c68/IMG_20210822_WA0040.jpg", "scribbleSpace": "Reading,writing, visiting places", "contribution": "I hv teaching and research experience", "intro": "Assistant professor"}',
  '{"name": "Poorvi Sharma", "place": "Pune", "waNumber": "919167335120", "email": "poorvi.sharma31@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6c4f8936321c71f51a870e9daedd6d0bb241a410d014611a75d1364fb87b7838/20210620_185318.jpg", "scribbleSpace": "When away from work, I enjoy abstract painting and find immense pleasure in bringing her imaginative senses to the canvas.", "contribution": "Haven\'t thought through, but can teach abstract painting.", "intro": "Product Marketing Person, Painter"}',
  '{"name": "Snehal Bhutada", "place": "Hyderabad", "waNumber": "919959668174", "email": "snehalbhutada@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4c80a9e496ac790abba09dadefa2095ab8c6030220f7ba36e270697463c49a32/C972D366_60F3_446F_8612_2BBE1CC07374.jpeg", "scribbleSpace": "I like travelling, yoga, and dance", "contribution": "I can share HR Knowledge with all", "intro": "I am peace loving person"}',
  '{"name": "Devesh Deshpande", "place": "Nagpur, Maharashtra.", "waNumber": "919372329548", "email": "devadeshpande14@gmail.com", "photoLine": "https://api.typeform.com/responses/files/260f04def6022b0a57d2acdb6017ff78e2c33fc0a95cbebc97989fcb576cc094/IMG_20201114_WA0006.jpg", "scribbleSpace": "I am passionate in VLSI. I am thankful for whatever I have got till now. I love to help people and I believe in collaboration.", "contribution": "Just Keep giving without any expectation of return. Focus on the present, do your karma and focus on the controllables of your goal.", "intro": "Peace Lover"}',
  '{"name": "Protima Venugopal", "place": "Undri Pune", "waNumber": "919823699865", "email": "protimafeb@yahoo.co.in", "photoLine": "https://api.typeform.com/responses/files/f7f4539985d571bfa6200d6128171114c92cf17983bb3139a9701a057e16106a/Polish_20211114_135325588.jpg", "scribbleSpace": "A freelance writer and fine artist who wants to grow more as an artist and a musician. Joined the club to be on track on my career goals and my physical and mental well being", "contribution": "Music and arts", "intro": "Writer and artist"}',
  '{"name": "Mrs Meghana Mahajan", "place": "Bandra, Mumbai", "waNumber": "919619369154", "email": "meghananm@gmail.com", "photoLine": "https://api.typeform.com/responses/files/caa4fd03f5f4a06c7944745576f6149c298d3048e323cece95fb5587bac3e31e/16380759566581125839450540654837.jpg", "scribbleSpace": "Solving sudoku, baking", "contribution": "Just want to observe as of now", "intro": "Yoga practitioner"}',
  '{"name": "Charuta", "place": "Sus road, Pune", "waNumber": "919822012622", "email": "charuta.gaikwad@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d6ddcd0642993caa5f95f35c5b7152bbd5c0a916d618bf3ee093311dfb604e6b/IMG20200504175714.jpg", "scribbleSpace": "Love everything that has Soul..", "contribution": "Happiness", "intro": "Life Itself"}',
  '{"name": "Kavitha Reddy", "place": "Hyderabad", "waNumber": "919177063544", "email": "yeruvakavithareddi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3bea44dda7357c6d900eec31029c3507c037e162824c51603e3a38d2267e5595/Screenshot_2021_0630_190954.png", "scribbleSpace": "Interested in knowing new things, like playing sports, good @cooking", "contribution": "Can share life experience s", "intro": "Interested in knowing new things, like playing sports, good @cooking"}',
  '{"name": "Harshada N. Kulkarni", "place": "Bibawewadi punpune", "waNumber": "919422317191", "email": "nirav.v.kulkarni@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0421946037c315ab340536a31a2d0c426ee4806e2749d033e22b6fff283797b8/IMG_20210829_223947.jpg", "scribbleSpace": "Drawing is my hobby, love to explore new things ,plans to be healthy n and happy ..love to read books do many things which will explore my inner strength.", "contribution": "Have excellence intreating infertility cases any and everything with homeopathy .love to do councelling.", "intro": "Homoeopathic consultant"}',
  '{"name": "SANIYA PANDKAR", "place": "Dhanori Pune", "waNumber": "919822909021", "email": "manupandkar176@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8273b261f86761fea9f284852e16b96eb4de91f53b894b3a01a0bfa7961e819f/IMG_20210228_173322_528.jpg", "scribbleSpace": "I am a spiritual person....I love meditating reading spiritual books and meeting new people ...looking forward to new experiences", "contribution": "Meditation experiences", "intro": "Yoga Practitioner"}',
  '{"name": "Poonam Mandke", "place": "Aundh Pune", "waNumber": "919970921230", "email": "poonammandhyani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b66c5a06e25058417b5734fd576d53c4035a3865a07cf274fc723fe26ca3865d/IMG_20210717_095819.jpg", "scribbleSpace": "I like music and dance, like being healthy and get people healthy at mind body and soul. I wish to help India get more healthy and generate more work opportunities for women.", "contribution": "I can help upcoming entrepreneurs with brainstorming for ideas or execution plan and strategy. I can help being a buddy to help reach some goals. Basically accountability partner.", "intro": "Health buddy"}',
  '{"name": "Bipin Advant", "place": "Bavdhan Pune", "waNumber": "919881491130", "email": "bipinadvant@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0812841b8ee5debf0c45f8cb0b51efe97bebefabdda55805fd21163381928960/IMG_20210530_234901.jpg", "scribbleSpace": "Music, positive thinking.\\nLooking for life long habit of waking up early", "contribution": "Power of gratitude. Some good music links", "intro": "IT professional"}',
  '{"name": "Omkar Mandke", "place": "Aundh pune", "waNumber": "919860833310", "email": "ommandke@gmail.com", "photoLine": "https://api.typeform.com/responses/files/022bde9cb32f02033ea4e22f0f3d9332896b7aadd92191b36a700b58f25a53c8/IMG_20210620_WA0028.jpg", "scribbleSpace": "Want to exercise daily. Finding it difficult to wake up early.", "contribution": "Tenting, camping and treks.", "intro": "Team Player"}',
  '{"name": "Abhay Nandan", "place": "Muzaffarpur", "waNumber": "917667969872", "email": "abhaynandan88@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c57f40d273c3e966632d8b2daeb7be0571952f4d1f04b87b22968b00ec0a259b/16382696806654746519312171195325.jpg", "scribbleSpace": "Business, spirituality,singing . Want to utilise the morning Time for gym,reading and relation building", "contribution": "Singing, entrepreneurial experience of 10years in different sectors like education, agriculture,poultry, FMCG distribution.", "intro": "Singer, entrepreneur"}',
  '{"name": "Ashish Sharma", "place": "Malad East-Mumbai", "waNumber": "919619730766", "email": "ashish.at.iit@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9f0cb9e10c7165dcc7c7fa4dd5b212236ed3e4ff026eb363a0a2afe4da70fd02/1AB42CCD_8F6C_4D78_BB87_CF9D4EB9FA00.jpeg", "scribbleSpace": "Sports, outdoor activities, looking forward to regulate life", "contribution": "Not sure as of now.", "intro": "Yoga Practitioner"}',
  '{"name": "Madhura Rale", "place": "Aranyeshwar, Pune", "waNumber": "919860347975", "email": "madhura76@gmail.com", "photoLine": "https://api.typeform.com/responses/files/17aa005a61a9154c4472cff48550c4595d5df2d5316e2ef7844ac04d37fe13a5/Screenshot_20210101_193848_WhatsApp.jpg", "scribbleSpace": "A mad curious person", "contribution": "Not sure... currently in receiving mode", "intro": "Curious biochemist"}',
  '{"name": "Anjum", "place": "Bavdhan", "waNumber": "919881069879", "email": "anjumysr@gmail.com", "photoLine": "https://api.typeform.com/responses/files/bdd1b4e1ecda9b013413404a525fe48d7f14250a53174d39fe50024abc770402/356B2130_24EF_4034_9DDB_4DDC37CEF38B.jpeg", "scribbleSpace": "Like writing Shayaris, exploring spirituality.. and want to being myself and live life on my own conditions", "contribution": "Human Resource", "intro": "Shayar"}',
  '{"name": "Avadhoot", "place": "Borivali- Mumbai", "waNumber": "919850887914", "email": "avadhootkhare@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d1415ea8bfbb1455f58dd414bc737786360bb40eba36ee28bd0005d58f8eff2d/1C7E0A77_CDDA_49D2_8FEA_2ADA676ED4CC.jpeg", "scribbleSpace": "I love Travelling. Always be happy", "contribution": "Rotary Machine specialist, additionally I am an Investment adviser", "intro": "Rotary Engineer"}',
  '{"name": "Priyanka Yeruva", "place": "Hyderabad", "waNumber": "917013030364", "email": "priyamyers@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fe71b929805493180c3e4c5a94df4f7fb654e41b6c269bd4ef7abe9f9e8c5746/IMG_20211120_110857.jpg", "scribbleSpace": "Cooking", "contribution": "NA", "intro": "Content specialist"}',
  '{"name": "Anu Prabhakar", "place": "BARC, Mumbai", "waNumber": "919820160969", "email": "prabhakaranu93@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ebebb56b65f4318d9b91334a195d44e96bf409bb27600dbe4442f809320d3d7c/IMG_20200412_WA0004.jpg", "scribbleSpace": "With the start of this program I want to be make finish maximum of my non office duties in morning such as exercise, yoga. \\nPlan to journal my daily morning update for these 21 days. \\nGoal to be more morning friendly", "contribution": "Maybe some exercise tips", "intro": "Ambivert, love nature, athlete"}',
  '{"name": "Paras Chhabra", "place": "New Delhi", "waNumber": "919999656662", "email": "paraschhabra96@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b44e03ec40a118aa68550ad82ef06ea7cf8127d29a4deaea3cd50a22e9c5c7aa/20210123_075025.jpg", "scribbleSpace": "Learning yoga and on the path to understand spirituality.", "contribution": "Podcasting for beginners", "intro": "Software Engineer"}',
  '{"name": "Deepa Murthy", "place": "Mira Road East, Mumbai", "waNumber": "919004343718", "email": "advocatedeepamurthy@gmail.com", "photoLine": "https://api.typeform.com/responses/files/bd3ed5e00e4b3b9be805ee3363011757df7a0bce5845268e92afb9a56c5f51eb/IMG_20211028_124627.jpg", "scribbleSpace": "I love reading, dancing, singing, art etc. I want to enjoy each moment of my life \\ud83d\\ude42", "contribution": "Art & craft activities", "intro": "Reader"}',
  '{"name": "Shravani", "place": "Hyderabad", "waNumber": "919440550580", "email": "shravani.thurlapati@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3c55b479cff21533ee2768402691b1438b2f84de03eba7620e6c62b227662c4d/Screen_Shot_2021_11_30_at_9.51.32_PM.png", "scribbleSpace": "Good listener, Nature Lover, Interest in Yoga, Art and Music", "contribution": "Something about Yoga and Healthy eating", "intro": "Yoga Enthusiast"}',
  '{"name": "Smita Mehetre", "place": "Baner, Pune", "waNumber": "919890395931", "email": "rtsmita@gmail.com", "photoLine": "https://api.typeform.com/responses/files/78b6dd4862fe15231a94b90ff0b39fef5c5f5052b14bcaa9c03a81d9e72677fc/IMG_20210511_WA0001.jpg", "scribbleSpace": "Practice Iyengar yoga, interested in full time farming, love slow sustainable lifestyle. Developing interest in reading.", "contribution": "I love to do handwork, sewing, crochet, making something out of waste. Made many small projects using left over fabric", "intro": "Sustainable living practitione"}',
  '{"name": "Pritam Potghan", "place": "Moshi Pradhikaran, Pune", "waNumber": "919075002244", "email": "potghanpritam@gmail.com", "photoLine": "https://api.typeform.com/responses/files/dde3eb33d351bbc38a3b8f227d6ce3a93b1df66564930ca5bd87fe24aacbba2d/IMG20201018173557.jpg", "scribbleSpace": "I love reading spiritual and self help books, writing in bullet journal, meditating. I like psychology and mythology. I am ambitious to be a Surgeon, a Doctor, to save lives.", "contribution": "The power of reading, Meditation, how to deal with depression, Guide to karma, study tips, things i do in my me time.", "intro": "Aspiring Medical student"}',
  '{"name": "Bhoomica Asnani", "place": "Thane Mumbai", "waNumber": "919987540067", "email": "bhoomica.asnani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/858bcafe379ed59115e899addff0d80019c66182daa62c730749283bb2fefaf6/IMG_20211106_WA0065.jpg", "scribbleSpace": "Wanna fulfill dreams of my kids", "contribution": "Experience of being a mother.", "intro": "Creative Keedaa"}',
  '{"name": "Pallavi Dhoot", "place": "Pune, Senapati Bapat Road", "waNumber": "919822008007", "email": "pallavidhoot@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/481eb608bce182acc4df23d94aae588e4f8c34ad3e573cb78f2d5b430c9ec35e/16250665649071373088638.jpg", "scribbleSpace": "I am a homemaker mother.\\nA researcher by \\nnature; passionate about nature, environment, health and spirituality. I facilitate a Naturopathy Awareness and Practice Program by the name \'Healthy Me\'", "contribution": "I would love to share about health, nutrition, natural healing, medicine free life & about parenting", "intro": "An Avid Explorer"}',
  '{"name": "Pranav Patil", "place": "Parvati Pune", "waNumber": "919822322184", "email": "pensafe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fc949309ee0e158a2e044e0a403d861dcd7e48f6f6869dc5f0ee51124395bd9f/Screenshot_20210624_162326__01.jpg", "scribbleSpace": "Psychologist body-builder, INFJ, Counsellor-Mentor-Advisor \\ud83d\\ude07, Scientist, Astrology-curious, Work-out-holic \\ud83d\\udcaa, Nasha-positive person", "contribution": "1. How to be happy 2. Micro- workouts at home 3. Make good tea 4. Socializing tips 5. Tinker on piano", "intro": "Armwrestling enthusiast"}',
  '{"name": "Manisha Bhosale", "place": "Kothrud Pune", "waNumber": "919890039898", "email": "manisha_bhosale@hotmail.com", "photoLine": "https://api.typeform.com/responses/files/1e4bbc7211010e211bda51616531b08e07a837838e0fa54dd183685ab434e3d8/8F9D9EC9_8A9E_4C6F_A639_A8EA95954313.png", "scribbleSpace": "Certified Yoga Trainer. Took up yoga as an activity to improve health and also as a fruitful activity after quitting the job. Presently the routine is a bit disturbed due to minor health issues and also lack of discipline. Trying to get my energy and health back by following Pallavi Dhoot\\u2019s HealthyMe programme and making dietary and lifestyle changes, trying to wake up early being one of them", "contribution": "I could try to answer any queries related to yogasanas, pranayama and shuddhi kriya", "intro": "Yoga practitioner"}',
  '{"name": "Dhanamma Ganechari", "place": "Shelgi Solapur", "waNumber": "917218164569", "email": "ganecharid@gmail.com", "photoLine": "https://api.typeform.com/responses/files/215fb6c9bc315c3cafa3481bf7fc3f0d5f1eda9f9f97ee62c2aca34a8e9d5f20/Screenshot_2021_0512_232804.png", "scribbleSpace": "Learning new things", "contribution": "Yoga, Accountancy, tally etc", "intro": "Teacher"}',
  '{"name": "Phani Raj Bhargava", "place": "Hyderabad", "waNumber": "919000988633", "email": "phani.moksha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2a91048a8eff67f4bf3e9c7ad14010fd4334c1663dd20ac2005e538c13ab2117/20210829_213919.jpg", "scribbleSpace": "Passionate about startups and teying to build one of them", "contribution": "I can share about what not to do in business .. \\ud83d\\ude04", "intro": "Entrepreneur"}',
  '{"name": "Sagar Gangurde", "place": "Kanjurmarg East, Mumbai.", "waNumber": "919594960105", "email": "sagarspg@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ee5b0ff4cb64d5ba42dce3e53849c6d2c755d7599fb1a55e768db99fae58b4d8/B7326774_BE55_4756_BB62_905ED898B6DC.jpeg", "scribbleSpace": "I work for an international NGO which works in peace building. I am also a comprehensive sexuality educator and facilitator. I want to maximise my time and my potential and hence want to form some powerful habits. Waking up early being one of them and hence i would like your support in forming this habit.", "contribution": "Peace building, conflict transformation, coaching, NLP, NVC, IFS, comprehensive sexuality education, peace education, mediation and negotiation skills.", "intro": "Peace Builder"}',
  '{"name": "Kalpesh Dhruve", "place": "Pimple Saudagar, Pune", "waNumber": "919923145886", "email": "kdhruve2@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ea83a0c26ddf8b60eb285cbadb84e08531c0a59f7fa76597a26ece43fc7106a6/20210102_120936.jpg", "scribbleSpace": "Outdoor Sports, Making short phrases/Rhymes, Working towards redeeming myself", "contribution": "Financial, Investment Advisory,", "intro": "Practising Chartered Accountant"}',
  '{"name": "Tejaswini Kapure", "place": "Kalyaninagar", "waNumber": "919881236959", "email": "tejaswini.kapure@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3d424da8b8743e840e4e6a09497f53486a0ad40506e52bdb2754a3bd6dc1da32/E6DF5570_C231_46B7_9BAE_D8B14A2EFD0F.jpeg", "scribbleSpace": "Just another human, trying my best to go through this journey of life.", "contribution": "I can help you with your Public speaking\\nI can also help with editing, delivering your speech. :)", "intro": "Funny meme lover"}',
  '{"name": "Umesh Kedari", "place": "Katraj Pune", "waNumber": "919850355691", "email": "umesh.kedari@gmail.com", "photoLine": "https://api.typeform.com/responses/files/72e716e345216a58fdd9f46ef50ef577fba33868fb139f3cea7bbaeb285ae1e1/IMG_20200521_094042.jpg", "scribbleSpace": "Listening Indian classical music, Gardening", "contribution": "Experience", "intro": "Mechanical Engineer"}',
  '{"name": "Pramod Mujumdar", "place": "Pune", "waNumber": "919923456651", "email": "pramod.mujumdar51@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8b3cc8de7343a496f72b124ed85100d41bd48fc71e43b4a42fbdef435ea62e58/IMG_20211129_183721_499.jpg", "scribbleSpace": "I am fun loving person, love to make new friends and innovative cooking makes me happy", "contribution": "Cooking", "intro": "Innovative chef, Candid Photog"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
]


var takenOut = [
  ]